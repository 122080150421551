import React, {useEffect} from 'react';

const facebookAppId = "107659234110915"

const CustomerChat = () => {

  useEffect(()=>{
    window.fbAsyncInit = function() {
      FB.init({
        xfbml            : true,
        version          : 'v7.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  })

  return(
    <>
      <div id="fb-root" />
      <div className="fb-customerchat"
        attribution="setup_tool"
        page_id={facebookAppId}
        theme_color="#000000"
        logged_in_greeting="Hi there! Are you looking for a photography session?"
        logged_out_greeting="Hi there! Are you looking for a photography session?">
      </div>
    </>
  )
}

export default CustomerChat
