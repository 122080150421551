import BootstrapProvider from '@bootstrap-styled/provider'
import Helmet from 'react-helmet'
import Footer from '../Section/Footer'
import MyNavbar from '../Common/Navbar'
import React from 'react'
import SEO from '../Common/SEO'
import CustomerChat from '../Common/CustomerChat'
import theme from '../../utils/constants'

const TemplateWrapper = ({ children }) => {
  return (
    <BootstrapProvider theme={theme} reset={true} injectGlobal={true}>
      <>
        <SEO />
        <Helmet>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
        </Helmet>
        <main>
          <CustomerChat />
          <MyNavbar listenToScroll={true} />
          {children}
        </main>
        <Footer />
      </>
    </BootstrapProvider>
  )
}

export default TemplateWrapper
