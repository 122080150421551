import { StaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import React from 'react'
import SchemaOrg from './SchemaOrg'

function SEO({
  description,
  lang,
  meta,
  keywords,
  image: metaImage,
  title,
  pathname,
  isBlogPost,
  author,
  datePublished = false,
  dateModified = false,
}) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              author
              siteUrl
              siteVerification {
                google
                bing
              }
              social {
                twitter
              }
              socialLinks {
                twitter
                linkedin
                facebook
                stackOverflow
                github
                instagram
                pinterest
                youtube
                email
                phone
                fax
                address
              }
              keywords
              organization {
                name
                url
              }
            }
          }
        }
      `}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaUrl = data.site.siteMetadata.siteUrl
        const image =
          metaImage && metaImage.src ? `${data.site.siteMetadata.siteUrl}${metaImage.src}` : null
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang: 'en',
              }}
              title={title}
              meta={[
                {
                  name: 'description',
                  content: metaDescription,
                },
                {
                  name: 'keywords',
                  content: data.site.siteMetadata.keywords.join(','),
                },
                {
                  property: `og:type`,
                  content: isBlogPost ? `article` : `website`,
                },
                {
                  property: 'og:title',
                  content: title,
                },
                {
                  property: 'og:description',
                  content: metaDescription,
                },
                {
                  name: 'twitter:creator',
                  content: data.site.siteMetadata.author,
                },
                {
                  name: 'twitter:title',
                  content: title,
                },
                {
                  name: 'twitter:description',
                  content: metaDescription,
                },
              ]
                .concat(
                  metaImage
                    ? [
                        {
                          property: 'og:image',
                          content: image,
                        },
                        {
                          property: 'og:image:width',
                          content: metaImage.width,
                        },
                        {
                          property: 'og:image:alt',
                          content: image.alt,
                        },
                        {
                          property: 'og:image:height',
                          content: metaImage.height,
                        },
                        {
                          name: 'twitter:card',
                          content: 'summary_large_image',
                        },
                        {
                          property: 'twitter:image:alt',
                          content: image.alt,
                        },
                      ]
                    : [
                        {
                          name: 'twitter:card',
                          content: 'summary',
                        },
                      ]
                )
                .concat(
                  // handle HTTPS Image
                  metaImage && metaImage.indexOf('https') > -1
                    ? [
                        {
                          property: 'twitter:image:secure_url',
                          content: metaImage,
                        },
                        { property: 'og:image:secure_url', content: metaImage },
                      ]
                    : []
                )
                .concat(meta)}
            />
            <SchemaOrg
              isBlogPost={isBlogPost}
              url={metaUrl}
              title={title}
              image={metaImage}
              description={metaDescription}
              datePublished={datePublished}
              dateModified={dateModified}
              canonicalUrl={data.site.siteMetadata.siteUrl}
              author={isBlogPost ? author : data.site.siteMetadata.author}
              organization={data.site.siteMetadata.organization}
              defaultTitle={title}
            />
          </>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  meta: [],
}

// SEO.propTypes = {
//   description: PropTypes.string,
//   image: PropTypes.shape({
//     src: PropTypes.string,
//     height: PropTypes.string,
//     width: PropTypes.string,
//   }),
//   meta: PropTypes.array,
//   title: PropTypes.string.isRequired,
// };

export default SEO
