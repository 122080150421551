import LogoSVG from '../../assets/svg/logo.inline.svg'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'

const Brand = styled(LogoSVG)`
  & {
    height: 54px;
  }
  & path {
    fill: ${props => (props.isTop && !props.isOpened ? theme.colors.white : theme.colors.darkGray)};
    transition: all 0.5s;
    transition-property: opacity, fill;
  }
`

export default Brand
