import { A } from '@bootstrap-styled/v4'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const S = {}

S.A = styled(A)`
  &:hover {
    text-decoration: none;
    color: unset;
  }
  color: unset;
`

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...props }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <S.A
        tag={GatsbyLink}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...props}
      >
        {children}
      </S.A>
    )
  }
  return (
    <S.A href={to} {...props}>
      {children}
    </S.A>
  )
}

export default Link
