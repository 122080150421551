import { Col, Row, Button, H1, P} from '@bootstrap-styled/v4'

import Link from '../Common/Link'
import Container from '../Common/Container'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'

import ScrollAnimation from 'react-animate-on-scroll';

const S = {}

S.Link = styled(Link)`
  &&& {
    margin-right: 10px;
    color: #333333;
    font-family: ${theme.fonts.header};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-left: 10px;
    ${theme.breakpoints.lg} {
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: unset;
    }
  }
  &&&:hover {
    color: #666666;
  }
`

S.Row = styled(Row)`
  margin: 1rem 0;
  align-items: center;
  width: 100%;
  padding-top: 12rem;
`

S.CopyrightCol = styled(Col)`
  margin-bottom: 6em;

  ${theme.breakpoints.sm} {
    margin-bottom: 7em;
  }

  ${theme.breakpoints.md} {
    margin-bottom: 8em;
  }
`

S.LinkCol = styled(Col)`
  display: unset;
  flex-direction: column;
  position: absolute;

  ${theme.breakpoints.lg} {
    display: unset;
    text-align: unset;
  }
`

S.Container = styled(Container)`
  text-align: center;
  background-color: ${theme.colors.lighterGray};
  margin-top: 8rem;
  padding-top: 6rem;
`

S.Title = styled(H1)`
  font-family: ${theme.fonts.article};
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
  color: ${theme.colors.darkGray}

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[6]}px;
    margin-bottom: 2rem;
  }
`

S.Text = styled(P)`
  font-size: ${theme.fontSizes[2]}px;
  line-height: 2;
  color: ${theme.colors.gray};
  text-align: center;
  margin-bottom: 2rem;

  ${theme.breakpoints.md} {
    margin-bottom: 2rem;
  }

  ${theme.breakpoints.xl} {
    font-size: ${theme.fontSizes[3]}px;
  }
`

S.P = styled(P)`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #666666;
  margin-bottom: 10px;
  font-size: 12px;

  ${theme.breakpoints.lg} {
    padding: 0px 0px;
    margin-bottom: unset;
    text-align: center;
    display: flex;
  }
`

S.CopyrightText = styled(S.P)`
  margin-top: 2em;

  ${theme.breakpoints.md} {
    margin-top: 4em;
  }
`

S.RightCol = styled(Col)`
  text-align:left;
`

S.LeftCol = styled(Col)`
  text-align:right;
`

S.NewRow = styled(Row)`
  display: flex;
  flex-direction: row;
`

const Footer = class extends React.Component {
  render() {
    return (
      <S.Container fluid>
        {/* <S.LinkCol>
          <S.NewRow>
            <S.LeftCol>
              <S.Link to="/">
                Home
              </S.Link>
            </S.LeftCol>

            <S.RightCol>
              <S.Link to="/contact">
                Contact Us
              </S.Link>
            </S.RightCol>
          </S.NewRow>
        </S.LinkCol> */}
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <S.Title>Looking for a photography service?</S.Title>
          <S.Text>Get in touch with us now to find out more about our service. We will get back to you as soon as possible. We look forward to hearing from you.</S.Text>
          <S.Link to="/contact"><Button outline size="lg">Get in touch</Button></S.Link>
        </ScrollAnimation>
        <S.Row className="mx-0 my-0" noGutters>
          <S.LinkCol xs="12">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <S.Link href="https://www.facebook.com/Hourglass-Studio-107659234110915" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} size = '3x'/>
              </S.Link>
              <S.Link href="https://www.instagram.com/hourglassstudio.co" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size = '3x'/>
              </S.Link>
              <S.Link href="mailto:contact@hourglassstudio.co">
                <FontAwesomeIcon icon={faEnvelope} size = '3x'/>
              </S.Link>
            </ScrollAnimation>
          </S.LinkCol>
          <S.CopyrightCol xs="12">
            <ScrollAnimation animateIn="fadeInUp" animateOnce offset="150">
              <S.CopyrightText>© 2018 - 2020 Hourglass Studio</S.CopyrightText>
            </ScrollAnimation>
          </S.CopyrightCol>
        </S.Row>


      </S.Container>
    )
  }
}

export default Footer
