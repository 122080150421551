import {
  Collapse,
  Container,
  H5,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Button,
  P
} from '@bootstrap-styled/v4'

import Brand from './Brand'
import Link from './Link'
import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/constants'

const S = {}

S.Nav = styled(Nav)`
  width: 100%;
`

S.Navbar = styled(Navbar)`
  &&& {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background-color: white;
    background-color: ${props =>
      props.isTop && !props.isOpened ? 'rgba(255,255,255,0)' : theme.colors.white};
    transition: background-color ${props => (props.isTop ? '0.05s' : '0.5s')} ease-in;
  }
`

S.Container = styled(Container)`
  ${theme.breakpoints.xl} {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
`

S.NavbarToggler = styled(NavbarToggler)`
  &&& {
    align-self: center !important;
    border-color: ${props =>
      props.isTop && !props.isOpened ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.1)'};

    ${theme.breakpoints.xl} {
      display: none;
    }

    & > span {
      background-image: ${props =>
        props.isTop && !props.isOpened
          ? `url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba(255, 255, 255, 0.8)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E')`
          : `url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba(0, 0, 0, 0.5)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E')`};
    }
  }
`

S.Collapse = styled(Collapse)`
  ${theme.breakpoints.xl} {
    display: flex !important;
    width: 100%;
  }

  &.collapse > .navbar-nav {
    ${theme.breakpoints.xl} {
      flex-direction: row;
    }
  }

  &.collapsing {
    transition: height 0.35s ease;
    transition-delay: 0.05s;
  }
`

S.NavItem = styled(NavItem)`
  margin-top: auto;
  margin-bottom: auto;
`

S.ContactNavItem = styled(NavItem)`
  ${theme.breakpoints.xl} {
    margin-left: auto;
  }
`

S.NavTitle = styled(H5)`
  &&& {
    color: #333;
    font-family: ${theme.fonts.header};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-bottom: 0px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    transition: color 0.5s;

    ${theme.breakpoints.xl} {
      color: ${props => (props.isTop ? theme.colors.white : theme.colors.darkGray)};
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  &&&:hover {
    color: ${props => (props.isTop ? '#A0A0A0' : theme.colors.lightGray)};
  }
`

S.ContactButton = styled(Button)`
  && {
    color: ${theme.colors.darkGray};
    border-color: ${theme.colors.darkGray};
    transition: color 0.5s, border-color 0.5s;

    ${theme.breakpoints.xl} {
      color: ${props => (props.isTop ? theme.colors.white : theme.colors.darkGray)};
      border-color: ${props => (props.isTop ? theme.colors.white : theme.colors.darkGray)};
    }
  }

  &&:hover {
    color: ${props => (props.isTop ? theme.colors.darkGray : theme.colors.white)};
    background-color: ${props => (props.isTop ? theme.colors.white : theme.colors.darkGray)};
  }
`

S.Text = styled(P)`
  margin-bottom: 0px;
`

class MyNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false,
      listenToScroll: props.listenToScroll,
      isTop: true,
    }
  }

  componentDidMount() {
    if (this.state.listenToScroll) {
      document.addEventListener('scroll', () => {
        if (window.scrollY >= 100) {
          this.setState({ isTop: false })
        } else {
          this.setState({ isTop: true })
        }
      })
    }
  }

  render() {
    return (
      <S.Navbar
        isTop={this.state.isTop}
        isOpened={this.state.isOpened}
        className="sticky-top"
        light
        toggleable="lg"
      >
        <S.Container>
          <div isTop={this.state.isTop} className="d-flex justify-content-between">
            <NavbarBrand tag={Link} to="/">
              <Brand isTop={this.state.isTop} isOpened={this.state.isOpened} />
            </NavbarBrand>
            <S.NavbarToggler
              isTop={this.state.isTop}
              isOpened={this.state.isOpened}
              onClick={() => this.setState({ isOpened: !this.state.isOpened })}
            />
          </div>
          <S.Collapse navbar isOpen={this.state.isOpened}>
            <S.Nav navbar>
              <S.NavItem>
                <NavLink className="px-1" tag={Link} to="/#about">
                  <S.NavTitle isTop={this.state.isTop}>About</S.NavTitle>
                </NavLink>
              </S.NavItem>
              <S.NavItem>
                <NavLink className="px-1" tag={Link} to="/#wedding">
                  <S.NavTitle isTop={this.state.isTop}>Wedding</S.NavTitle>
                </NavLink>
              </S.NavItem>
              <S.NavItem>
                <NavLink className="px-1" tag={Link} to="/#professional">
                  <S.NavTitle isTop={this.state.isTop}>Professional Profile</S.NavTitle>
                </NavLink>
              </S.NavItem>
              <S.NavItem>
                <NavLink className="px-1" tag={Link} to="/#graduation">
                  <S.NavTitle isTop={this.state.isTop}>Graduation</S.NavTitle>
                </NavLink>
              </S.NavItem>
              <S.ContactNavItem>
                <NavLink className="px-1" tag={Link} to="/contact">
                  <S.ContactButton outline isTop={this.state.isTop}>
                    <S.Text>Get in touch</S.Text>
                  </S.ContactButton>
                </NavLink>
              </S.ContactNavItem>
            </S.Nav>
          </S.Collapse>
        </S.Container>
      </S.Navbar>
    )
  }
}

export default MyNavbar
